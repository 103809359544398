export default {
  Name: 'ARDI',
  Header: {
    TitleStep1: 'Давайте настроим вашу игру',
    TitleStep2: 'Почти готово!',
    TitleStep3: 'Ваше бронирование завершено! Спасибо!',
    TitleStep4: 'Ой...',
  },
  Success: {
    text: `Если вы не получили подтверждение о бронировании по электронной почте, мы рекомендуем посмотреть в папке со спамом.<br><br>Если вы выбрали способ оплаты - Счет, то мы отправим подтверждение бронирования в течение дня.<br><br>Приготовьтесь к приключениям!`,
    btn1: 'Забронировать другую игру',
    btn2: 'Вернуться на главную',
  },
  Failed: {
    text: `Мы не смогли завершить транзакцию.<br>Попробуйте пожалуйста снова!`,
    btn1: 'Попробовать снова',
    btn2: 'Вернуться на главную',
  },
  Language: 'Язык',
  ChooseLanguage: 'Выберите язык',
  Date: 'Дата',
  Game: 'Игра',
  ChooseAGame: 'Выбрать игру',
  WhenDoYouWantToPlay: 'Когда вы хотите поиграть?',
  AndWhichTime: 'А в какое время?',
  HowManyAreYou: 'Количество участников',
  AvailableImmediately: 'Доступна сразу',
  Teams: 'Число команд',
  NumberOfPeople: 'Число игроков',
  HowManyAreYouNote:
    'ВАЖНО! Оптимальное количество игроков в команде - {min}-{max}. Если вас больше, мы разделим вас на разные команды.',
  Choose: 'Выбрать',
  ChooseCategory: 'Выбрать категорию',
  GameType: 'Kатегория',
  Episode: 'Эпизод',
  Price: 'Цена',
  InclVat: 'Включая НДС',
  ThereShouldBeAtLeastNPlayers: 'Должно быть не менее {min} игроков.',
  TheNumberOfPeopleExceeds:
    'Количество человек превышает максимальное количество участников в одной команде, поэтому количество команд будет: {count}',
  PhoneMaxLength: 'В телефоне должно быть не более {max} символов.',
  Next: 'Далее',
  Voucher: 'Ваучер',
  ByClickingTheConfirmButton: 'Нажимая кнопку «Подтвердить», вы соглашаетесь с',
  TermsOfService: 'Условиями Пользования',
  PrivacyPolicy: 'Политикой Конфиденциальности',
  And: 'и',
  Form: {
    Fields: {
      FullName: 'Полное имя',
      FirstName: 'Имя',
      SecondName: 'Фамилия',
      Email: 'Электронная почта',
      Phone: 'Номер телефона',
      Discount: 'Промо-код',
      ChoosePaymentMethod: 'Выберите способ оплаты',
      Invoice: 'Счет (только для корпоративных клиентов)',
      CardPayment: 'Оплата картой',
      OrganisationNumber: 'Организационный номер',
      InvoiceEmail: 'E-mail для счета',
      CompanyName: 'Название компании',
      Address: 'Адрес',
      PostCode: 'Почтовый индекс',
      City: 'Город',
      ContactPerson: 'Контактное лицо',
      OtherInformation: 'Другая информация',
      IAmInterestedInReceivingInformation:
        'Я заинтересован в получении информации о скидках и обновлениях по электронной почте.',
      BookingInformation: 'Информация о бронировании',
    },
    Active: 'Активировать',
    Change: 'Изменить',
    Back: 'Назад',
    Confirm: 'Подтвердить',
    Validation: {
      Required: 'Поле обязательное для заполнения',
      Email: 'Введите правильный адрес электронной почты',
      EnterValidFormat: 'Введите правильный формат',
      EnterValidNumbers: 'Введите только числа',
      EnterAValidPhoneNumber: 'Введите правильный номер телефона',
      PleaseFillTheFormCorrectly: 'Пожалуйста, заполните форму правильно',
      Latin: 'Только латинские буквы',
    },
  },
  MeetingPoint: 'Место встречи',
  PromoCodeWrong: 'Неверный промокод',
  link: {
    terms: "/en/terms-and-conditions",
    privacy: "/en/privacy-policy",
  },
};
