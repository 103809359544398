import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import sv from './sv';
import ru from './ru';

Vue.use(VueI18n);

const messages = { sv, en, ru };

export default new VueI18n({
  locale: 'sv',
  fallbackLocale: 'sv',
  messages: process.env.VUE_APP_LANGUAGES.split(',').reduce(
    (acc, locale) => ({ ...acc, [locale]: messages[locale] }),
    {},
  ),
});
