import { isNumber } from 'lodash';
import Vue from 'vue';
import Router from 'vue-router';
import i18n from './locales';

Vue.use(Router);

const localeParam = `:locale(${process.env.VUE_APP_LANGUAGES.split(',').join('|')})`;

/*const routes = [
  {
    path: '',
    redirect: `/book/${i18n.locale}`,
  },
  {
    path: '/',
    redirect: `/book/${i18n.locale}`,
  },
  {
    path: '/book',
    redirect: `/book/${i18n.locale}`,
  },
  {
    path: '/:locale(sv|en|ru)/:type?',
    redirect: `/book/${i18n.locale}/:type?`,
  },
  {
    path: '/:locale(sv|en|ru)/:type?/:language?',
    redirect: `/book/${i18n.locale}/:type?/:language?`,
  },
  {
    path: '/:locale(sv|en|ru)/:type?/:language?/:id?',
    redirect: `/book/${i18n.locale}/:type?/:language?/:id?`,
  },
  {
    path: '*',
    redirect: `/book/${i18n.locale}`,
  },
  {
    path: '/book/:locale(sv|en|ru)',
    component: {
      render: (h) => h('router-view'),
    },
    props: true,
    children: [
      {
        path: '/book/:locale(sv|en|ru)/:type/confirm',
        name: 'ConfirmBooking',
        component: () => import('./views/ConfirmBooking'),
        meta: {
          layout: 'booking',
        },
      },
      {
        path: '/book/:locale(sv|en|ru)/:type/thanks',
        name: 'BookingThanks',
        props: false,
        component: () => import('./views/BookingThanks'),
        meta: {
          layout: 'booking',
        },
      },
      {
        path: '/book/:locale(sv|en|ru)/:type/thanks/:stripeCheckoutSessionId',
        name: 'BookingThanksId',
        props: true,
        component: () => import('./views/BookingThanks'),
        meta: {
          layout: 'booking',
        },
      },
      {
        path: '/book/:locale(sv|en|ru)/:type/oops-payment-failed-or-canceled',
        name: 'BookingFailed',
        props: false,
        component: () => import('./views/BookingFailed'),
        meta: {
          layout: 'booking',
        },
      },
      {
        path: ':type?/:language?/:id?',
        name: 'ChooseGame',
        component: () => import('./views/ChooseGame'),
        meta: {
          layout: 'booking',
        },
      },
    ],
  },
];*/

const newRoutes = [
  {
    path: '',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '*',
    redirect: `/${i18n.locale}`,
  },
  {
    path: `/book/${localeParam}/:type?/:language?/:id?`,
    redirect: `/${i18n.locale}`,
  },
  {
    path: `/${localeParam}`,
    name: 'ChooseGame',
    component: () => import('./views/ChooseGame'),
    meta: {
      layout: 'booking',
    },
  },
  {
    path: `/${localeParam}/confirm`,
    name: 'ConfirmBooking',
    component: () => import('./views/ConfirmBooking'),
    meta: {
      layout: 'booking',
    },
  },
  {
    path: `/${localeParam}/thanks`,
    name: 'BookingThanks',
    props: false,
    component: () => import('./views/BookingThanks'),
    meta: {
      layout: 'booking',
    },
  },
  {
    // path: `/${localeParam}/thanks-invoice/:booking_id`,
    path: `/${localeParam}/thanks-invoice`,
    name: 'BookingThanksInvoice',
    props: false,
    component: () => import('./views/BookingThanks'),
    meta: {
      layout: 'booking',
    },
  },
  {
    path: `/${localeParam}/thanks/:stripeCheckoutSessionId`,
    name: 'BookingThanksId',
    props: true,
    component: () => import('./views/BookingThanks'),
    meta: {
      layout: 'booking',
    },
  },
  {
    path: `/${localeParam}/oops-payment-failed-or-canceled`,
    name: 'BookingFailed',
    props: false,
    component: () => import('./views/BookingFailed'),
    meta: {
      layout: 'booking',
    },
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: newRoutes,
});

router.beforeEach((to, from, next) => {
  let language = to.params.locale;
  if (!language) {
    language = 'sv';
  }
  i18n.locale = language;
  window.scrollTo(0, 0);
  if (to.redirectedFrom) {
    const redirectedParams = to.redirectedFrom.split('/').filter((param) => param !== '');
    const params = { locale: 'sv' };
    const query = to.query || {};
    if (redirectedParams[1] && ['sv', 'ru', 'en'].includes(redirectedParams[1])) {
      params.locale = redirectedParams[1];
    }
    if (redirectedParams[2] && ['cityquest', 'homequest', 'officequest'].includes(redirectedParams[2])) {
      query.type = redirectedParams[2];
    }
    if (redirectedParams[3] && ['sv', 'ru', 'en'].includes(redirectedParams[3])) {
      query.language = redirectedParams[3];
    }
    if (redirectedParams[4] && isNumber(parseInt(redirectedParams[4]))) {
      query.id = parseInt(redirectedParams[4]);
    }

    next({ name: 'ChooseGame', params, query });
  } else {
    next();
  }
});

export default router;
