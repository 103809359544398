import Vue from 'vue';
import Rollbar from 'vue-rollbar';

if (process.env.NODE_ENV === 'production') {
  Vue.use(Rollbar, {
    accessToken: '7d8d1ab442d74f33b436f069b207c1b3',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    environment: 'production',
    payload: {
      client: {
        javascript: {
          code_version: '1.0',
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
    },
  });

  // eslint-disable-next-line no-unused-vars
  Vue.config.errorHandler = function (err, vm, info) {
    Vue.rollbar.error(err);
  };
}
