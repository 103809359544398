export default {
  Name: "ARDI",
  Header: {
    TitleStep1: "Let’s set up your game now",
    TitleStep2: "Almost there",
    TitleStep3: "Your booking is complete! Thank you!",
    TitleStep4: "Oops...",
  },
  Success: {
    text: `If you didn't receive a confirmation email we recommend checking your junk mail.<br><br>If you chose to pay with an invoice, we will send a booking confirmation during the day.<br><br>Get ready for an adventure!`,
    btn1: "Book another game",
    btn2: "Back to homepage",
  },
  Failed: {
    text: `We were unable to process your payment.<br>Please try again!`,
    btn1: "Try again",
    btn2: "Back to homepage",
  },
  Language: "Language",
  ChooseLanguage: "Choose language",
  Date: "Date",
  Game: "Game",
  ChooseAGame: "Choose game",
  WhenDoYouWantToPlay: "When do you want to play?",
  AndWhichTime: "And which time?",
  HowManyAreYou: "How many are you?",
  AvailableImmediately: "Available immediately",
  Teams: "Number of teams",
  NumberOfPeople: "Number of people",
  HowManyAreYouNote:
    "NOTE! The optimal number of players per team is {min}-{max}. If you are more, we will divide you into different teams.",
  Choose: "Choose",
  ChooseCategory: "Choose category",
  GameType: "Category",
  Episode: "Episode",
  Price: "Price",
  InclVat: "Incl. VAT",
  ThereShouldBeAtLeastNPlayers: "There should be at least {min} players.",
  TheNumberOfPeopleExceeds:
    "The number of people exceeds the maximum amount of participants in one team, so the number of teams will be: {count}",
  PhoneMaxLength: "Phone must have at most {max} numbers",
  Next: "Next",
  Voucher: "Voucher",
  ByClickingTheConfirmButton: 'By clicking "Confirm" you agree to ARDI',
  TermsOfService: "Terms of Service",
  PrivacyPolicy: "Privacy Policy",
  And: "and",
  Form: {
    Fields: {
      FullName: "Full name",
      FirstName: "First name",
      SecondName: "Second name",
      Email: "E-mail",
      Phone: "Phone number",
      Discount: "Promo code",
      ChoosePaymentMethod: "Choose payment method",
      Invoice: "Invoice (corporate customers only)",
      CardPayment: "Card payment",
      OrganisationNumber: "Organisation number",
      InvoiceEmail: "Invoice Email",
      CompanyName: "Company name",
      Address: "Address",
      PostCode: "Zip code",
      City: "City",
      ContactPerson: "Contact person",
      OtherInformation: "Other information",
      IAmInterestedInReceivingInformation:
        "I am interested in receiving information, discounts and updates via e-mail",
      BookingInformation: "Booking information",
    },
    Active: "Activate",
    Change: "Change",
    Back: "Back",
    Confirm: "Confirm",
    Validation: {
      Required: "Field is required",
      Email: "Enter a valid email address",
      EnterValidFormat: "Enter valid format",
      EnterValidNumbers: "Enter valid numbers",
      EnterAValidPhoneNumber: "Enter a valid phone number",
      PleaseFillTheFormCorrectly: "Please fill the form correctly.",
      Latin: "Only Latin characters are allowed.",
    },
  },
  MeetingPoint: "Meeting point",
  PromoCodeWrong: "The promo code is incorrect",
  link: {
    terms: "/en/terms-and-conditions",
    privacy: "/en/privacy-policy",
  },
};
