<template>
  <div class="app">
    <header class="app-header pt-3 pt-md-4 pb-2 pb-md-3 bg-faint-weak">
      <div class="container">
        <div class="row d-flex align-items-start">
          <div
            class="col-12 order-md-1 col-md-3 d-flex justify-content-center justify-content-md-end mb-3"
          >
            <select
              class="form-control lang-select"
              aria-label="Set locale"
              v-model="$i18n.locale"
              @change="changeLocale"
            >
              <option v-for="locale in locales" :key="locale" :value="locale">
                {{ locale.toUpperCase() }}
              </option>
            </select>
          </div>
          <div class="col-12 order-md-0 col-md-6 offset-md-3">
            <div class="text-center">
              <h1 class="heading-2 text-accent-strong" v-text="$t(`Header.TitleStep${step}`)" />
              <ul class="step-dots">
                <li :class="{ active: step === 1 }" />
                <li :class="{ active: step === 2 }" />
                <li :class="{ active: [3, 4].includes(step) }" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main
      class="app-main bg-accent-strong pt-3 pt-md-4 pb-3 pb-md-5"
      :class="{ loading }"
    >
      <transition name="fade" mode="out-in">
        <router-view @onChangeStep="setStep" />
      </transition>
      <div class="container layout-footer">
        <div class="row">
          <div class="col-12 pb-3 pt-5">
            <div class="text-center">
              <div class="footer-text mb-4 mb-md-5">
                <span>ARDI Developed by </span>
                <span>© ARDI GLOBAL LLC</span>
              </div>
              <img :src="require('@/assets/images/payment-methods.svg')" alt="Payment icons"/>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BookingLayout',
  data: () => ({
    locales: [],
    step: 1,
  }),
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    changeLocale() {
      this.$router.push({
        name: this.$router.history.current.name,
        params: { locale: this.$i18n.locale },
        query: this.$route.query,
      });
    },
    setStep(step) {
      this.step = step;
    },
  },
  mounted() {
    this.locales = Object.keys(this.$i18n.messages);
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/utilities/utilities';

.step-dots {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    background-color: $danger-weak;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 10px;
    @include media(md) {
      width: 18px;
      height: 18px;
    }

    &.active {
      background-color: $danger-strong;
    }
  }
}

.layout-footer {
  .footer-text {
    display: flex;
    flex-direction: column;
    color: #56ccf2;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    @include media(md) {
      display: block;
      font-size: 1.25rem;
    }
  }

  img {
    max-width: 100%;
    display: inline-block;
    height: auto;
  }
}
</style>
