import moment from 'moment';

export default {
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm');
      }
    },
    countdownDate(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    money(value) {
      return value ? parseInt(value).toLocaleString() : 0;
    },
    fullLangName(value) {
      switch (value) {
        case 'en':
          return 'English';
        case 'sv':
          return 'Svenska';
        case 'ru':
          return 'Русский';
      }
    },
  },
};
