import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './services/http';
import i18n from './locales';

import './plugins';
import './filters';
import './mixins';

Vue.config.productionTip = false;
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
