import Vue from 'vue';
import Vuex from 'vuex';
import http from '../services/http';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    loading: false,
    bookable_games: [],
    booked_game: {},
    booking_details: null,
    booking_customer: {},
    booking_amount: {
      amount: 0,
      currency: 'USD',
    },
    discount: {
      is_active: false,
      discount_code: '',
    },
  },
  getters: {
    step: (state) => state.step,
    loading: (state) => state.loading,
    bookable_games: (state) => state.bookable_games,
    booked_game: (state) => state.booked_game,
    booking_details: (state) => state.booking_details,
    booking_customer: (state) => state.booking_customer,
    booking_amount: (state) => state.booking_amount,
    discount: (state) => state.discount,
  },
  mutations: {
    set_step(state, payload) {
      state.step = payload;
    },
    set_loading(state, payload) {
      state.loading = payload;
    },
    set_bookable_games(state, payload) {
      state.bookable_games = payload;
    },
    set_booked_game(state, payload) {
      state.booked_game = payload;
    },
    set_booking_details(state, payload) {
      state.booking_details = payload;
    },
    set_booking_customer(state, payload) {
      state.booking_customer = payload;
    },
    set_booking_amount(state, payload) {
      state.booking_amount = payload;
    },
    set_discount(state, payload) {
      state.discount = payload;
    },
    unset_booked_game(state) {
      state.booked_game = {};
    },
    unset_booking_details(state) {
      state.booking_details = null;
    },
    unset_booking_customer(state) {
      state.booking_customer = {};
    },
    unset_booking_amount(state) {
      state.booking_amount = {
        amount: 0,
        currency: 'USD',
      };
    },
    unset_discount(state) {
      state.discount = {
        is_active: false,
        discount_code: '',
      };
    },
  },
  actions: {
    save_step({ commit }, payload) {
      commit('set_step', payload);
    },
    async fetch_bookable_games({ commit }) {
      commit('set_loading', true);
      try {
        const { data } = await http.get('booking/bookable');
        commit('set_bookable_games', data);
      } catch (e) {
        console.error(e);
      } finally {
        commit('set_loading', false);
      }
    },
    save_booked_game({ commit }, payload) {
      commit('set_booked_game', payload);
    },
    save_booking_details({ commit }, payload) {
      commit('set_booking_details', payload);
    },
    save_booking_customer({ commit }, payload) {
      commit('set_booking_customer', payload);
    },
    save_booking_amount({ commit }, payload) {
      commit('set_booking_amount', payload);
    },
    fetch_discount({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http.post('booking/checkout', payload).then(
          (response) => {
            commit('set_discount', {
              is_active: response.data.discount_code !== '',
              ...response.data,
            });
            commit('set_booking_amount', response.data.total_amount);
            if (response.data.discount_code === '') {
              reject();
            } else {
              resolve(response);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    clear_booked_game({ commit }) {
      commit('unset_booked_game');
    },
    clear_booking_details({ commit }) {
      commit('unset_booking_details');
    },
    clear_booking_customer({ commit }) {
      commit('unset_booking_customer');
    },
    clear_booking_amount({ commit }) {
      commit('unset_booking_amount');
    },
    clear_discount({ commit }) {
      commit('unset_discount');
    },
  },
});
