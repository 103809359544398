import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import router from '../router';

Vue.use(VueGtm, {
  id: 'GTM-NJMLWTR',
  enabled: true,
  debug: true,
  vueRouter: router,
});
