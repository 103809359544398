<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
  </div>
</template>

<script>
import BookingLayout from './layouts/BookingLayout';

export default {
  name: 'App',
  components: {
    BookingLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'booking'}-layout`;
    },
  },
};
</script>

<style lang="scss">
@import '../src/styles/main.scss';
</style>
